import React from 'react';

function HeaderSection() {
  return (
    <div className="header-container">
      <div id="snow_fall" />{" "}
      <div
        className="home-marker animate__animated d-block animate__backInDown"
        style={{ display: "none" }}
      >
        <ul>
          <li id={1} className="jumpmenu">
            <i className="fas fa-bullhorn" />
          </li>{" "}
          <li id={2} className="jumpmenu">
            <i className="fas fa-video" />
          </li>{" "}
          <li id={3} className="jumpmenu">
            <i className="fas fa-user-secret" />
          </li>{" "}
          <li id={4} className="jumpmenu active">
            <i className="far fa-clipboard" />
          </li>
        </ul>
      </div>{" "}
      <img
        data-cfsrc="/img/in14fLuUr.jpg"
        alt=""
        className="d-block w-100 h-100 h-cover"
        src="http://www.dichvu.fun/img/in14fLuUr.jpg"
      />{" "}
      <div>
        <button
          type="button"
          className="btn btn-white rounded-circle btn-music"
        >
          <span className="circle-us" />{" "}
          <i className="fa-light fa-music-note fa-spin" />
        </button>{" "}
        <audio id="music" loop="loop" autoPlay="autoplay" className="d-none">
          <source src="/music/giangsinh.mp3" type="audio/mp3" />
        </audio>
      </div>{" "}
      <div className="container">
        <div className="logo">
          <img
            data-cfsrc="/img/in14fLuUr.png"
            src="http://www.dichvu.fun/img/in14fLuUr.png"
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
