import React from 'react';

function CharacterTabs() {
  return (
    <div>



      <div id="jump3" className="page-section charachers-bg">
        <div className="home-heading">
          <h2>Nhân Vật</h2>
        </div>{" "}
        <div className="tab-character">
          <div className="row">
            <div className="col-md-12">
              <div id="pills-tabContent" className="tab-content">
                <div
                  id="pills-c-0"
                  role="tabpanel"
                  aria-labelledby="pills-c-0-tab"
                  className="tab-pane fade show active"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/NMewXz6Orv.png"
                          alt="Thúy Yên"
                          src="http://www.dichvu.fun/characters/NMewXz6Orv.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Thủy có các loại thuộc tính tương đối
                          bình thường, không có gì là đặc biệt, cũng không có
                          thuộc tính nào mạnh nhất cả.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Vũ khí: Đao/Song Đao </p>
                        </div>{" "}
                        <p>
                          -Hệ Thủy chỉ để cho nữ giới lựa chọn, đó có thể là
                          phái Nga My chính phái hoặc phái Thúy Yên trung lập.
                          Võ công của nữ nhi đều mềm mại và uyển chuyển như các
                          cô vậy. Đệ tử phái Nga My phổ độ chúng sinh, có thể
                          tăng thêm rất nhiều sức lực cho người khác. Thúy Yên
                          cũng có rất nhiều thuật phòng thân, không truyền cho
                          người ngoài....
                        </p>{" "}
                        <a href="./character/12" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-1"
                  role="tabpanel"
                  aria-labelledby="pills-c-1-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/5WvZx8AcK9.png"
                          alt="Cái Bang"
                          src="http://www.dichvu.fun/characters/5WvZx8AcK9.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Hỏa là những người cường tráng, có
                          sức mạnh tương đương với những người của hệ Kim, tu
                          luyện nội công đặc thù đúng cách cũng có thể mang lại
                          hiệu quả tốt.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Hỏa</p>
                        </div>{" "}
                        <p>
                          Nơi hệ Hỏa có thể bái sư học võ là Cái Bang thuộc
                          Chính phái: danh trấn Giang hồ nhờ dùng Đả Cẩu Bổng
                          Pháp và Giáng Long thập Bát Chưởng uy danh thiên hạ,
                          chỉ truyền cho những người có duyên. Thiên Nhẫn thuộc
                          tà phái: Võ công Thiên Ma Giải Thể của Thiên Nhẫn
                          chuyên dạy cách dụng lửa cũng là một kỳ thuật mà tương
                          truyền là võ công của Tà giáo nơi Tây Vực....
                        </p>{" "}
                        <a href="./character/11" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-2"
                  role="tabpanel"
                  aria-labelledby="pills-c-2-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/oPk6Njrc2V.png"
                          alt="Côn Lôn"
                          src="http://www.dichvu.fun/characters/oPk6Njrc2V.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Thổ, trời sinh thông minh hơn người,
                          xương cốt cứng cáp, thích hợp để tu luyện nội công.
                          Thế nhưng bù lại, thể chất lại là yếu nhất trong tất
                          cả những thuộc tính, sinh mạng tăng lên ít hơn so với
                          những thuộc tính khác.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Thổ</p>
                        </div>{" "}
                        <p>
                          -Hệ Thổ chỉ có thể gia nhập đạo gia. Võ Đang chính
                          phái và Côn Lôn trung lập đều là đạo này. Võ Đang chú
                          trọng tu luyện nội công và kiếm thuật, Côn Lôn lại tập
                          trung vào đạo thuật truyền thống....
                        </p>{" "}
                        <a href="./character/10" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-3"
                  role="tabpanel"
                  aria-labelledby="pills-c-3-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/T4dniDDZ5o.png"
                          alt="Ngũ Độc"
                          src="http://www.dichvu.fun/characters/T4dniDDZ5o.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Mộc thân pháp linh hoạt, phản ứng
                          nhanh nhẹn, nhưng khi thăng cấp + luyện ngoại công thì
                          việc tăng sinh mạng không thể hiện rõ, do yếu tố thể
                          chất nên luyện tập nội công cũng có phần bất lợi.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Mộc</p>
                        </div>{" "}
                        <p>
                          -Hệ Mộc có thể gia nhập phái Đường Môn phía Trung Lập
                          và Ngũ Độc Giáo phía Tà phái. Những môn phái đó tuy
                          không chiến đấu chính diện được lâu dài như Thiếu Lâm,
                          song nhờ bộ pháp nhanh nhẹn, công thủ linh hoạt nên có
                          thể sử dụng tuyệt học độc môn. Võ lâm không ai không
                          lo sợ ám khí bí truyền cùng các tinh diệu của Đường
                          Môn cũng như tài dụng độc của Ngũ Độc giáo....
                        </p>{" "}
                        <a href="./character/9" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-4"
                  role="tabpanel"
                  aria-labelledby="pills-c-4-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/Bm34Kuyejo.png"
                          alt="Thiên Nhẫn"
                          src="http://www.dichvu.fun/characters/Bm34Kuyejo.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Hỏa là những người cường tráng, có
                          sức mạnh tương đương với những người của hệ Kim, tu
                          luyện nội công đặc thù đúng cách cũng có thể mang lại
                          hiệu quả tốt.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Hỏa</p>
                        </div>{" "}
                        <p>
                          -Nơi hệ Hỏa có thể bái sư học võ là Cái Bang thuộc
                          Chính phái: danh trấn Giang hồ nhờ dùng Đả Cẩu Bổng
                          Pháp và Giáng Long thập Bát Chưởng uy danh thiên hạ,
                          chỉ truyền cho những người có duyên. Thiên Nhẫn thuộc
                          tà phái: Võ công Thiên Ma Giải Thể của Thiên Nhẫn
                          chuyên dạy cách dụng lửa cũng là một kỳ thuật mà tương
                          truyền là võ công của Tà giáo nơi Tây Vực....
                        </p>{" "}
                        <a href="./character/8" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-5"
                  role="tabpanel"
                  aria-labelledby="pills-c-5-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/5BndziJFCj.png"
                          alt="Võ Đang"
                          src="http://www.dichvu.fun/characters/5BndziJFCj.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Thổ, trời sinh thông minh hơn người,
                          xương cốt cứng cáp, thích hợp để tu luyện nội công.
                          Thế nhưng bù lại, thể chất lại là yếu nhất trong tất
                          cả những thuộc tính, sinh mạng tăng lên ít hơn so với
                          những thuộc tính khác
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Thổ</p>
                        </div>{" "}
                        <p>
                          -Hệ Thổ chỉ có thể gia nhập đạo gia. Võ Đang chính
                          phái và Côn Lôn trung lập đều là đạo này. Võ Đang chú
                          trọng tu luyện nội công và kiếm thuật, Côn Lôn lại tập
                          trung vào đạo thuật truyền thống....
                        </p>{" "}
                        <a href="./character/7" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-6"
                  role="tabpanel"
                  aria-labelledby="pills-c-6-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/JsdJbY4ESC.png"
                          alt="Thiên Vương"
                          src="http://www.dichvu.fun/characters/JsdJbY4ESC.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc Hành Kim có sức mạnh hơn người, thân thể
                          cường tráng; khi thăng cấp sinh mạng sẽ được tăng lên
                          rất nhanh, tác dụng khi luyện tập ngoại công cũng mạnh
                          hơn thấy rõ. Mỗi khi sinh mạng tăng lên một cấp thì
                          ngoại công cũng tăng lên rất nhiều
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Kim</p>
                        </div>{" "}
                        <p>
                          Người thuộc hành Kim luyện tập nội công thì sẽ bất
                          lợi. Nếu lựa chọn hành này, bạn có thể chọn môn phái
                          Thiếu Lâm thuộc Chính phái và Thiên Vương Bang ở vị
                          trí trung lập. Điểm giống nhau giữa hai môn phái này
                          là tấn công gần, thiên về ngoại công và có thể sử dụng
                          thành thạo phần lớn thập bát võ công....
                        </p>{" "}
                        <a href="./character/6" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-7"
                  role="tabpanel"
                  aria-labelledby="pills-c-7-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/ZazFxQVXpL.png"
                          alt="Nga Mi"
                          src="http://www.dichvu.fun/characters/ZazFxQVXpL.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc hành Thủy có các loại thuộc tính tương đối
                          bình thường, không có gì là đặc biệt, cũng không có
                          thuộc tính nào mạnh nhất cả.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Thủy</p>
                        </div>{" "}
                        <p>
                          -Hệ Thủy chỉ để cho nữ giới lựa chọn, đó có thể là
                          phái Nga My chính phái hoặc phái Thúy Yên trung lập.
                          Võ công của nữ nhi đều mềm mại và uyển chuyển như các
                          cô vậy. Đệ tử phái Nga My phổ độ chúng sinh, có thể
                          tăng thêm rất nhiều sức lực cho người khác. Thúy Yên
                          cũng có rất nhiều thuật phòng thân, không truyền cho
                          người ngoài....
                        </p>{" "}
                        <a href="./character/5" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-8"
                  role="tabpanel"
                  aria-labelledby="pills-c-8-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/JmNy79DawF.png"
                          alt="Thiếu Lâm"
                          src="http://www.dichvu.fun/characters/JmNy79DawF.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Người thuộc Hành Kim có sức mạnh hơn người, thân thể
                          cường tráng; khi thăng cấp sinh mạng sẽ được tăng lên
                          rất nhanh, tác dụng khi luyện tập ngoại công cũng mạnh
                          hơn thấy rõ. Mỗi khi sinh mạng tăng lên một cấp thì
                          ngoại công cũng tăng lên rất nhiều.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Kim</p>
                        </div>{" "}
                        <p>
                          -Người thuộc hành Kim luyện tập nội công thì sẽ bất
                          lợi. Nếu lựa chọn hành này, bạn có thể chọn môn phái
                          Thiếu Lâm thuộc Chính phái và Thiên Vương Bang ở vị
                          trí trung lập. Điểm giống nhau giữa hai môn phái này
                          là tấn công gần, thiên về ngoại công và có thể sử dụng
                          thành thạo phần lớn thập bát võ công...
                        </p>{" "}
                        <a href="./character/4" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-9"
                  role="tabpanel"
                  aria-labelledby="pills-c-9-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/HfjlLSHnq2.png"
                          alt="Đường Môn"
                          src="http://www.dichvu.fun/characters/HfjlLSHnq2.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Thuộc hành Mộc thì thân pháp linh hoạt, phản ứng nhanh
                          nhẹn nhưng khi thăng cấp + luyện ngoại công thì việc
                          tăng sinh mạng không thể hiện rõ. Do thể chất nên
                          luyện công có phần bất lợi.
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Mộc</p>
                        </div>{" "}
                        <p>
                          -Hệ Mộc có thể gia nhập phái Đường Môn phía Trung Lập
                          và Ngũ Độc Giáo phía Tà phái. Môn phái đó tuy không
                          chiến đấu chính diện lâu dài như Thiếu Lâm, song nhờ
                          bộ pháp nhanh nhẹn, công thủ linh hoạt nên có thể sử
                          dụng tuyệt học độc môn. Võ lâm không ai không lo sợ ám
                          khí bí truyền cùng những cơ quan tinh diệu của Đường
                          Môn cũng như tài dụng độc của Ngũ Độc giáo...
                        </p>{" "}
                        <a href="./character/2" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-10"
                  role="tabpanel"
                  aria-labelledby="pills-c-10-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/pngegg.png"
                          alt="Hoa Sơn"
                          src="http://www.dichvu.fun/characters/pngegg.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Đệ tử phái Hoa Sơn chia thành tu thân hữu vi và tu tâm
                          vô vi, kiếm tông nghiên cứu chiêu thức tinh xảo kỳ
                          diệu, vạn pháp tinh thông; khí tông nghiên cứu cầu
                          chân tại tâm, nhất pháp thông vạn pháp..
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Thủy</p>
                        </div>{" "}
                        <p>
                          -Đệ tử Hoa Sơn rất hiếm khi xuống núi, chỉ khi nào tu
                          vi võ công đạt đến cảnh giới nhất định mới xuống núi
                          để rèn luyện trải nghiệm, đột phá chướng ngại, mong có
                          thể khiến tu vi của bản thân đạt đến tầm cao mới, là
                          một phương pháp để tìm thấy chính mình. Người phái Hoa
                          Sơn có một sức mạnh khiến người ta kính phục nhưng lại
                          vô cùng khiêm tốn, ôn hòa....
                        </p>{" "}
                        <a href="./character/2" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-11"
                  role="tabpanel"
                  aria-labelledby="pills-c-11-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/vuhon-dao.png"
                          alt="Vũ Hồn"
                          src="http://www.dichvu.fun/characters/vuhon-dao.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          {" "}
                          Võ Hồn là môn phái võ thuật tinh thông và uyển chuyển,
                          tập trung vào sử dụng linh hồn và năng lượng trong
                          chiến đấu. Các võ sư Võ Hồn có khả năng sử dụng các kỹ
                          năng và bùa chú để tăng cường sức mạnh và phòng thủ.
                          ..
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Thủy</p>
                        </div>{" "}
                        <p>
                          -Với sự linh hoạt và sức mạnh của mình, Võ Hồn là một
                          môn phái rất phù hợp cho những người chơi thích chiến
                          đấu từ xa và sử dụng những kỹ năng đặc biệt để đối phó
                          với các đối thủ mạnh....
                        </p>{" "}
                        <a href="./character/2" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  id="pills-c-12"
                  role="tabpanel"
                  aria-labelledby="pills-c-12-tab"
                  className="tab-pane fade show"
                >
                  <div className="row">
                    <div className="col-md-7">
                      <div className="text-center">
                        <img
                          data-cfsrc="/characters/cam-tieudao.png"
                          alt="Tiêu Dao"
                          src="http://www.dichvu.fun/characters/cam-tieudao.png"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-5">
                      <div className="character-info">
                        <p className="pb-3 text-warning">
                          Tiêu Dao là sự kết hợp giữa võ thuật và kỹ năng sử
                          dụng Cầm , đàn tì bà của tiêu dao có uy lực mạnh mẽ
                          đặc biệt là tỉ lệ mê hoặc nội công ..
                        </p>{" "}
                        <div className="border-bottom border-secondary p-0">
                          <p className="text-left">Thuộc tính: Thủy</p>
                        </div>{" "}
                        <p>
                          -Ngoài ra, Tiêu Dao còn có khả năng sử dụng các kỹ
                          năng di chuyển nhanh như "Phi Long Đao" để né tránh
                          đòn tấn công và tạo khoảng cách với đối thủ. ....
                        </p>{" "}
                        <a href="./character/2" className="btn btn-primary">
                          Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-12">
              <ul
                id="pills-tab"
                role="tablist"
                className="nav nav-pills nav-justified"
              >
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-0-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-0"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-0"
                    aria-selected="true"
                    className="nav-link active"
                  >
                    Thúy Yên
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-1"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-1"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Cái Bang
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-2"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-2"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Côn Lôn
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-3-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-3"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-3"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Ngũ Độc
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-4-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-4"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-4"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Thiên Nhẫn
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-5-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-5"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-5"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Võ Đang
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-6-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-6"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-6"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Thiên Vương
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-7-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-7"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-7"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Nga Mi
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-8-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-8"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-8"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Thiếu Lâm
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-9-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-9"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-9"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Đường Môn
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-10-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-10"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-10"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Hoa Sơn
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-11-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-11"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-11"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Vũ Hồn
                  </button>
                </li>{" "}
                <li role="presentation" className="nav-item">
                  <button
                    id="pills-c-12-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c-12"
                    type="button"
                    role="tab"
                    aria-controls="pills-c-12"
                    aria-selected="true"
                    className="nav-link"
                  >
                    Tiêu Dao
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CharacterTabs;
