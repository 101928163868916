import React from 'react';

function Footer() {
  return (
    <footer className="footer-container">
      <p className="footer-logo">
        <i className="fab fa-gg" /> &nbsp;JxMu Online
      </p>
      <p className="copyright">
        Copyright © 2022-2023 JxMu.vn, Inc. Script Tự Thân.
      </p>
      <span id="siteseal" className="mt-3 d-block text-center" />
    </footer>
  );
}

export default Footer;
