import React from 'react';

function NewsSection() {
  return (

    <div id="jump1" className="page-section position-relative">
      <div className="cloud-container-top">
        <div className="cloud-left-top">
          <img
            data-cfsrc="/img/cloud-left1-new.png"
            alt=""
            className="b-block w-100 h-100"
            src="http://www.dichvu.fun/img/cloud-left1-new.png"
          />
        </div>{" "}
        <div className="cloud-right-top">
          <img
            data-cfsrc="/img/cloud-right1-new.png"
            alt=""
            className="b-block w-100 h-100"
            src="http://www.dichvu.fun/img/cloud-right1-new.png"
          />
        </div>
      </div>{" "}
      <div className="home-heading">
        <h2>News</h2>
      </div>{" "}
      <div className="container news-bg position-relative">
        <a href="/all/news" className="view-more">
          More
        </a>{" "}
        <div className="d-flex align-items-start tabs-left row">
          <div
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
            className="nav flex-column nav-pills px-3 col-md-3"
          >
            <button
              id="v-pills-0-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-0"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link active"
            >
              Tin Nóng
            </button>{" "}
            <button
              id="v-pills-1-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-1"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link"
            >
              Hỗ Trợ Tân Thủ
            </button>{" "}
            <button
              id="v-pills-2-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-2"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link"
            >
              Đáng Chú Ý
            </button>{" "}
            <button
              id="v-pills-3-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-3"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link"
            >
              Mới Nhất
            </button>{" "}
            <button
              id="v-pills-4-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-4"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link"
            >
              Thông Tin Tính Năng
            </button>
          </div>{" "}
          <div id="v-pills-tabContent" className="tab-content col-md-9">
            <div
              id="v-pills-0"
              role="tabpanel"
              aria-labelledby="v-pills-0-tab"
              className="tab-pane fade show active"
            >
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/146">Sự Kiện Cửa hàng Lễ Hội max (Mới)</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-11-30
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/145">
                    Cập Nhật Phiên Bản 1.1.0 "Thu Đông" Mới !
                  </a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-11-15
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/144">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-31
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/141">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-17
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/140">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-07
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/139">Tin Tức Nóng Chưa Có&nbsp;</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-07
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/138">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-07
                  </span>
                </div>
              </div>
            </div>{" "}
            <div
              id="v-pills-1"
              role="tabpanel"
              aria-labelledby="v-pills-1-tab"
              className="tab-pane fade show"
            >
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/143">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-17
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/142">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-10-17
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/135">Tin Tức Nóng Chưa Có​</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-09-16
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/134">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-09-16
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/133">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-09-14
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/130">Tin Tức Nóng Chưa Có !</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-09-12
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/128">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2023-08-22
                  </span>
                </div>
              </div>
            </div>{" "}
            <div
              id="v-pills-2"
              role="tabpanel"
              aria-labelledby="v-pills-2-tab"
              className="tab-pane fade show"
            >
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/60">Tin Tức Nóng Chưa Có</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-12-22
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/59">
                    New Accessory - Fierce Phoenix Ring &amp; Jade
                  </a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-12-22
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/58">New Equipment - Fierce Martial Set</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-12-22
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/35">Chui Yan Dance Revamped!</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-09-06
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/34">Yang Clan Bow Revamped!</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-09-06
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/33">Beggar Sect Revamped!</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-09-06
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/32">Tang Clan Revamped!</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-09-06
                  </span>
                </div>
              </div>
            </div>{" "}
            <div
              id="v-pills-3"
              role="tabpanel"
              aria-labelledby="v-pills-3-tab"
              className="tab-pane fade show"
            >
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/64">The New Grade 6th School Equipment</a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-12-26
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/39">
                    Transfer WarPoints to Jpoints 10% Bonus
                  </a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-10-06
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/2">
                    There is no information yet about this page.
                  </a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-05-16
                  </span>
                </div>
              </div>
            </div>{" "}
            <div
              id="v-pills-4"
              role="tabpanel"
              aria-labelledby="v-pills-4-tab"
              className="tab-pane fade show"
            >
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/7">
                    There is no information yet about this page.
                  </a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-05-21
                  </span>
                </div>
              </div>{" "}
              <div className="row tab-list">
                <div className="col-md-10">
                  <a href="./news/6">
                    There is no information yet about this page.
                  </a>
                </div>{" "}
                <div className="col-md-2">
                  <span className="badge bg-dark text-light p-2">
                    2022-05-16
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default NewsSection;
