import React from 'react';

function History() {
  return (
    <div id="jump2" className="page-section home-container">
      <div className="home-heading">
        <h2>Lịch Sử JXMU</h2>
      </div>{" "}
      <div className="position-relative">
        <video
          muted="muted"
          poster="/img/video-poster.jpg"
          className="video-wrap"
        />{" "}
        <div
          data-bs-toggle="modal"
          data-bs-target="#modal-video-player"
          className="player"
        >
          <div className="player-bg" />{" "}
          <img
            data-cfsrc="/img/v1/player.png"
            alt=""
            className="player-icon"
            src="http://www.dichvu.fun/img/v1/player.png"
          />
        </div>
      </div>{" "}
      <div
        id="modal-video-player"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        className="modal fade"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <video
                width="100%"
                controls="controls"
                loop="loop"
                className="popup-video"
              >
                <source src="/videos/cliptieudao.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
