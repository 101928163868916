import React from 'react';
import Navbar from './components/Navbar';
import HeaderSection from './components/HeaderSection';
import CharacterTabs from './components/CharacterTabs';
import StorySection from './components/StorySection';
import Slider from './components/Slider';
import Footer from './components/Footer';
import NewsSection from './components/NewsSection'; // Renamed for consistency
import History from './components/History';
import NavbarSection from './components/NavbarSection';
import './App.css';

function App() {
  return (
    <div className="App" id="app">
      <Navbar />
      {/* <NavbarSection /> */}

      <main>
        <HeaderSection />
        <NewsSection />
        <History />
        <CharacterTabs />
        <StorySection />
        <Slider />
      </main>
      <Footer />
    </div>
  );
}

export default App;
