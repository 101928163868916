import React from 'react';

function Slider() {
  return (
    <div className="position-relative">
      <div className="home-heading">
        <h2>Ký Ức Còn Mãi</h2>
      </div>{" "}
      <div
        id="home-slider"
        data-bs-ride="carousel"
        data-bs-pause="false"
        className="carousel slide carousel-fade home-slider"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#home-slider"
            data-bs-slide-to={0}
            className=""
          />{" "}
          <button
            type="button"
            data-bs-target="#home-slider"
            data-bs-slide-to={1}
            aria-current="true"
            className="active"
          />{" "}
          <button
            type="button"
            data-bs-target="#home-slider"
            data-bs-slide-to={2}
            aria-current="true"
          />{" "}
          <button
            type="button"
            data-bs-target="#home-slider"
            data-bs-slide-to={3}
            aria-current="true"
          />{" "}
          <button
            type="button"
            data-bs-target="#home-slider"
            data-bs-slide-to={4}
            aria-current="true"
          />{" "}
          <button
            type="button"
            data-bs-target="#home-slider"
            data-bs-slide-to={5}
            aria-current="true"
          />
        </div>{" "}
        <div className="carousel-inner">
          <div data-bs-interval={5000} className="carousel-item">
            <img
              data-cfsrc="/slider/phu_ccbo.jpg"
              alt="..."
              className="d-block w-100"
              src="http://www.dichvu.fun/slider/phu_ccbo.jpg"
            />
          </div>{" "}
          <div data-bs-interval={5000} className="carousel-item active">
            <img
              data-cfsrc="/slider/phu_ccbo1.jpg"
              alt="..."
              className="d-block w-100"
              src="http://www.dichvu.fun/slider/phu_ccbo1.jpg"
            />
          </div>{" "}
          <div data-bs-interval={5000} className="carousel-item">
            <img
              data-cfsrc="/slider/phu_ccbo2.jpg"
              alt="..."
              className="d-block w-100"
              src="http://www.dichvu.fun/slider/phu_ccbo2.jpg"
            />
          </div>{" "}
          <div data-bs-interval={5000} className="carousel-item">
            <img
              data-cfsrc="/slider/phu_ccbo3.jpg"
              alt="..."
              className="d-block w-100"
              src="http://www.dichvu.fun/slider/phu_ccbo3.jpg"
            />
          </div>{" "}
          <div data-bs-interval={5000} className="carousel-item">
            <img
              data-cfsrc="/slider/phu_ccbo4.jpg"
              alt="..."
              className="d-block w-100"
              src="http://www.dichvu.fun/slider/phu_ccbo4.jpg"
            />
          </div>{" "}
          <div data-bs-interval={5000} className="carousel-item">
            <img
              data-cfsrc="/slider/phu_ccbo6.jpg"
              alt="..."
              className="d-block w-100"
              src="http://www.dichvu.fun/slider/phu_ccbo6.jpg"
            />
          </div>
        </div>
      </div>{" "}
      <div className="cloud-container-bottom">
        <div className="cloud-left-bottom">
          <img
            data-cfsrc="./img/cloud-left3.png"
            alt=""
            className="b-block w-100 h-100"
            src="http://www.dichvu.fun/img/cloud-left3.png"
          />
        </div>{" "}
        <div className="cloud-right-bottom">
          <img
            data-cfsrc="./img/cloud-right3.png"
            alt=""
            className="b-block w-100 h-100"
            src="http://www.dichvu.fun/img/cloud-right3.png"
          />
        </div>
      </div>
    </div>
  );
}

export default Slider;
