import React from 'react';

function StorySection() {
  return (

    <div id="jump4" className="page-section home-container">
      <div className="home-heading">
        <h2>Stories</h2>
      </div>{" "}
      <div className="container">
        <div className="d-flex align-items-start tabs-right row">
          <div id="v-pills-tabContent" className="tab-content col-md-9">
            <div
              id="v-pills-s-0"
              role="tabpanel"
              aria-labelledby="v-pills-s-0-tab"
              className="tab-pane fade show active"
            >
              <ol className="list-group mb-3">
                <li className="pl-0 list-group-item d-flex align-items-center align-items-start border-top-0 border-left-0 border-right-0 rounded-0 border-dashed border-2 border-secondary">
                  <img
                    data-cfsrc="/story/ieyW4Yug8j.jpeg"
                    alt="Tải hình nền JxMu"
                    width={70}
                    height={70}
                    className="rounded-circle border border-dark p-1"
                    src="http://www.dichvu.fun/story/ieyW4Yug8j.jpeg"
                  />{" "}
                  <div className="ml-2 mr-auto">
                    <a href="./story/5" className="text-dark">
                      Tải Hình nền JXMU{" "}
                    </a>
                  </div>{" "}
                  <br />
                  <span>2022-05-23</span>
                </li>
              </ol>
            </div>{" "}
            <div
              id="v-pills-s-1"
              role="tabpanel"
              aria-labelledby="v-pills-s-1-tab"
              className="tab-pane fade show"
            >
              <ol className="list-group mb-3">
                <li className="pl-0 list-group-item d-flex align-items-center align-items-start border-top-0 border-left-0 border-right-0 rounded-0 border-dashed border-2 border-secondary">
                  <img
                    data-cfsrc="/story/VgJAIOMP8n.jpeg"
                    alt="Bảng Xếp Hạng Top 10"
                    width={70}
                    height={70}
                    className="rounded-circle border border-dark p-1"
                    src="http://www.dichvu.fun/story/VgJAIOMP8n.jpeg"
                  />{" "}
                  <div className="ml-2 mr-auto">
                    <a href="./story/6" className="text-dark">
                      Bảng Xếp Hạng Top 10
                    </a>
                  </div>{" "}
                  <br />
                  <span>2022-05-23</span>
                </li>{" "}
                <li className="pl-0 list-group-item d-flex align-items-center align-items-start border-top-0 border-left-0 border-right-0 rounded-0 border-dashed border-2 border-secondary">
                  <img
                    data-cfsrc="/story/NAuCkeQo2V.jpeg"
                    alt="Hoạt Động Tết Nguyên Đán"
                    width={70}
                    height={70}
                    className="rounded-circle border border-dark p-1"
                    src="http://www.dichvu.fun/story/NAuCkeQo2V.jpeg"
                  />{" "}
                  <div className="ml-2 mr-auto">
                    <a href="./story/4" className="text-dark">
                      Hoạt Động Tết Nguyên Đán
                    </a>
                  </div>{" "}
                  <br />
                  <span>2022-05-23</span>
                </li>{" "}
                <li className="pl-0 list-group-item d-flex align-items-center align-items-start border-top-0 border-left-0 border-right-0 rounded-0 border-dashed border-2 border-secondary">
                  <img
                    data-cfsrc="/story/nWA0UGKaOt.jpeg"
                    alt="Vật Phẩm Mới : Trống Bang Hội !"
                    width={70}
                    height={70}
                    className="rounded-circle border border-dark p-1"
                    src="http://www.dichvu.fun/story/nWA0UGKaOt.jpeg"
                  />{" "}
                  <div className="ml-2 mr-auto">
                    <a href="./story/3" className="text-dark">
                      Vật Phẩm Mới : Trống Bang Hội !
                    </a>
                  </div>{" "}
                  <br />
                  <span>2022-05-23</span>
                </li>{" "}
                <li className="pl-0 list-group-item d-flex align-items-center align-items-start border-top-0 border-left-0 border-right-0 rounded-0 border-dashed border-2 border-secondary">
                  <img
                    data-cfsrc="/story/2.jpg"
                    alt="There is no information yet."
                    width={70}
                    height={70}
                    className="rounded-circle border border-dark p-1"
                    src="http://www.dichvu.fun/story/2.jpg"
                  />{" "}
                  <div className="ml-2 mr-auto">
                    <a href="./story/2" className="text-dark">
                      There is no information yet.
                    </a>
                  </div>{" "}
                  <br />
                  <span>2022-05-23</span>
                </li>{" "}
                <li className="pl-0 list-group-item d-flex align-items-center align-items-start border-top-0 border-left-0 border-right-0 rounded-0 border-dashed border-2 border-secondary">
                  <img
                    data-cfsrc="/story/1.jpg"
                    alt="There is no information yet."
                    width={70}
                    height={70}
                    className="rounded-circle border border-dark p-1"
                    src="http://www.dichvu.fun/story/1.jpg"
                  />{" "}
                  <div className="ml-2 mr-auto">
                    <a href="./story/1" className="text-dark">
                      There is no information yet.
                    </a>
                  </div>{" "}
                  <br />
                  <span>2022-05-23</span>
                </li>
              </ol>
            </div>
          </div>{" "}
          <div
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
            className="nav flex-column nav-pills px-3 col-md-3"
          >
            <button
              id="v-pills-s-0-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-s-0"
              type="button"
              role="tab"
              aria-selected="true"
              className="nav-link active"
            >
              Hình Nền
            </button>{" "}
            <button
              id="v-pills-s-1-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-s-1"
              type="button"
              role="tab"
              aria-selected="true"
              className="nav-link"
            >
              Thông Tin Mới
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StorySection;
