import React, { useState } from 'react';

function Navbar() {
  // State để quản lý trạng thái đóng/mở của navbar
  const [isOpen, setIsOpen] = useState(false);

  // Hàm để thay đổi trạng thái khi nhấn nút toggle
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm position-fixed w-100 theme-v1-nav">
      <div className="container">
        <a href="https://www.dichvu.fun" className="navbar-brand">
          <i className="fab fa-gg"></i>&nbsp;JxMU Online
        </a>
        <button
          type="button"
          data-toggle="collapse"
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
          className={`navbar-toggler ${isOpen ? '' : 'collapsed'}`}
          onClick={toggleNavbar} // Gọi hàm toggle khi nhấn nút
        >
          <span className="navbar-toggler-icon">
            <i className="fas fa-bars" style={{ color: 'rgb(255, 255, 255)', fontSize: '22px' }}></i>
          </span>
        </button>
        <div
          id="navbarSupportedContent"
          className={`navbar-collapse collapse ${isOpen ? 'show' : ''}`} // Thêm lớp 'show' nếu isOpen là true
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item text-uppercase">
              <a href="https://www.dichvu.fun" className="nav-link">Home</a>
            </li>
            <li className="nav-item text-uppercase">
              <a href="javascript:void(0)" className="nav-link nav-news">NEWS</a>
            </li>
            <li className="nav-item text-uppercase">
              <a href="javascript:void(0)" className="nav-link nav-character">Characters</a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item text-uppercase text-danger">
              <a href="https://www.dichvu.fun/download/game" className="nav-link">Download</a>
            </li>
            <li className="nav-item text-uppercase menu-bg">
              <a href="https://www.dichvu.fun/login" className="nav-link">Login</a>
            </li>
            <li className="nav-item text-uppercase menu-bg">
              <a href="https://www.dichvu.fun/register" className="nav-link">Register</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
